import React, { useContext, useState, useCallback } from "react"
import { graphql } from "gatsby"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
//import { compressToEncodedURIComponent } from "lz-string"

import { MyContext } from "../context/my-context"
import { remove } from "../context/records-reducer"

import { getNewCustomLsId } from "../util/data"
import { formatDateTime } from "../util/format"

import Select from "react-select"

import Seo from "../parts/seo"
import Layout from "../parts/layout"
import Row from "../parts/row"
import HeadingUnderline from "../parts/headingUnderline"
import { useDropzone } from 'react-dropzone'
import { getHashLS } from "../util/data"
import { upsert } from "../context/records-reducer"

const BuildYourOwn = ({ pageContext }) => {
  const { t, navigate } = useI18next()
  const { state, dispatch } = useContext(MyContext)

  const learningScenarios = pageContext.learningScenarios.allNodeLearningScenario.nodes

  const title = t("BUILD-TITLE")

  const [ original, setOriginal ] = useState(null)

  const originalOptions = learningScenarios.map(ls => { return { value: ls.id, label: ls.title } })

  const handleNewLsClick = () => {
    const newCustomLsId = getNewCustomLsId(state.ls)

    navigate(`/build-your-own/edit`, {
      state: {
        hiddenid: newCustomLsId,
        initialData: {
          title: t("BUILD-DEFAULT_LS_TITLE")
        }
      }
    })
  }

  const handleCustomiseLsClick = () => {
    const newCustomLsId = getNewCustomLsId(state.ls)

    navigate(`/build-your-own/edit`, {
      state: {
        hiddenid: newCustomLsId,
        cloneFrom: original?.value,
      }
    })
  }

  const handleDeleteCustomLsClick = (title, action) => {
    dispatch.dialog({
      title: "Are you sure?",
      body: <p>This action can not be undone. Are you sure you want to delete your Learning Scenario <strong>"{ title }"</strong>?</p>,
      buttons: [
        <button key={"no"} className="button" onClick={ () => dispatch.dialog(null) }>No, cancel</button>,
        <button key={"yes"} className="button" onClick={ () => { action(); dispatch.dialog(null) } }>Yes, delete</button>
      ]
    })
  }

  // const handleShareClick = (data) => {
  //   const c = compressToEncodedURIComponent(JSON.stringify(data))
  //   let url = new URL(window.location)
  //   url.pathname = "share/" + c

  //   dispatch.dialog({
  //     title: "Share this Learning Scenario",
  //     body: <>
  //       <p>You can share this Learning Scenario by sending the following URL:</p>
  //       <pre className="share-url">{ url.href }</pre>
  //     </>,
  //     buttons: [
  //       <button key={"copy"} className="button" onClick={ (e) => { navigator.clipboard.writeText(url.href); e.target.innerText = t("SHARE-COPIED_TO_CLIPBOARD") } }>
  //         Copy URL to clipboard
  //       </button>
  //     ]
  //   })
  // }

  // // export LS to file on local computer
  // const handleExportClick = (data) => {
  //   const c = JSON.stringify(data)
  //   const blob = new Blob([c], {type: 'application/json'});

  //   dispatch.dialog({
  //     title: t("EXPORT-DIALOG-TITLE"),
  //     body: <>
  //       <p>{ t("EXPORT-DIALOG-EXPLAINER") }</p>
  //     </>,
  //     buttons: [
  //       <a href={window.URL.createObjectURL(blob)} download={data.title}>
  //         <button key={"download"} className="button" onClick={ (e) => {  } }>
  //           {t("BUTTONS-EXPORT")}
  //         </button>
  //       </a>
  //     ]
  //   })
  // }

  const doImport = (ls) => {
    // new local id
    const newCustomLsId = getNewCustomLsId(state.ls)
    // store
    //console.log("storing: ", newCustomLsId)
    upsert(dispatch.ls, {
      ...ls,
      id: newCustomLsId
    })
    // store the fact that this was imported
    upsert(dispatch.imports, {
      id: newCustomLsId,
      state: "new"
    })
  }

  const makeImportDialog = (preview, messages) => {

    return {
      title: t("IMPORT-DIALOG_TITLE"),
      body: <div className="flow">
        <div {...getRootProps( {className: ["dropzone", (preview ? "preview" : undefined)].filter(Boolean).join(" ") }) }>
          <input {...getInputProps()} />
          { preview ?
            <p>{ preview.title }</p>
            :
            <p>{ t("IMPORT-DIALOG_DROPZONE") }</p>
          }
        </div>
        { (messages || []).map( (message, i) => <p key={i}><em className="ls-import-message">{ message }</em></p>) }
      </div>,
      buttons: [
        <button key="close" className="button" onClick={ () => { dispatch.dialog(null) }}>
          {t("BUTTONS-CANCEL")}
        </button>,
        (preview ?
          <button key="import" onClick={ () => { doImport(preview); dispatch.dialog(null) }} className="button">
          {t("BUTTONS-CONTINUE_IMPORT")}
          </button> : null
        )
      ]
    }
  }

  const handleImportClick = () => {
    dispatch.dialog(makeImportDialog)
  }

  const onDrop = useCallback((acceptedFiles) => {

    if(acceptedFiles.length === 0) {
      alert(t("UPLOAD-WRONG-TYPE"))
    }

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const ls = JSON.parse(reader.result)

        // find other versions, this only works for ls's with a base id
        const existing = (state?.ls || []).filter( (stored) => {
          // file uploads always have a base id
          return (ls.baseId === (stored.baseId || "olderVersionWithoutBaseId"))
        })

        // find identical version, this also works with older versions since the baseid is ignored for comparison
        const identical = (state?.ls || []).filter( (stored) => {
          // compare content only and not meta data
          return (getHashLS(stored) === getHashLS(ls))
        })

        let messages = []
        if(identical.length > 0) {
          messages.push(t("IMPORT-DIALOG_IDENTICAL_MESSAGE"))
        } else if(existing.length) {
          messages.push(t("IMPORT-DIALOG_VERSIONS_MESSAGE"))
        }

        // const messages = [
        //   ( existing.length > 0 ? t("IMPORT-DIALOG_VERSIONS_MESSAGE") : null ),
        //   ( identical.length > 0 ? t("IMPORT-DIALOG_IDENTICAL_MESSAGE") : null ),
        // ].filter( m => m)

        //
        dispatch.dialog(makeImportDialog(ls, messages))
      }
      reader.readAsText(file);

    })

  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
     'application/json': ['.json'],
   },
    maxFiles: 1
  })

  const customLearningScenarios = (state?.ls || []).reverse()
  const customLearningScenariosGrouped = customLearningScenarios.reverse().reduce((group, ls) => {
    const baseId = ls.baseId || ls.id
    group[baseId] = group[baseId] ?? []
    group[baseId].push(ls)
    return group
  }, {})

  return (
    <Layout currentNavItem="build-your-own">
      <Seo title={ title } />

      <Row>
        <div className="pane pad-m flow">
          <HeadingUnderline title={ title } classes={["center"]} />
          <p className="introduction">{ t("BUILD-INTRODUCTION") }</p>

          <div className="grid-columns flow-2x">
            <div className="pane-grey pad-s space-between-column flow">
              <p className="no-margin">{ t("BUILD-FROM_LS-TITLE") }</p>
              <Select options={ originalOptions } defaultValue={ original } blurInputOnSelect isSearchable={false} className="select-wrapper" classNamePrefix="select" onChange={option => { setOriginal(option) }} placeholder={ t("BUILD-FROM_LS-SELECT_PLACEHOLDER") } />
              <div className="ctas center">
                <button className="button button-go" onClick={ () => { handleCustomiseLsClick() } } disabled={ !original ? true : false } >{ t("BUILD-FROM_LS-BUTTON") }</button>
              </div>
            </div>

            <div className="pane-grey pad-s space-between-column flow">
            <p>{ t("BUILD-FROM_SCRATCH-TITLE") }</p>
              <div className="ctas center">
                <button className="button button-go" onClick={ () => handleNewLsClick() }>{ t("BUILD-FROM_SCRATCH-BUTTON") }</button>
              </div>
            </div>
          </div>
        </div>
      </Row>

      { state && <Row classes={["flow"]}>
        <div className="space-between">
          <h2>{ t("BUILD-MY_OWN-TITLE") }</h2>
          <button className="button button-small button-up" onClick={ () => { handleImportClick() } }>{ t("BUTTONS-IMPORT") }</button>
        </div>

        { state.ls.length === 0 && <p>{ t("BUILD-MY_OWN-NONE") }</p> }

        <div className="flow flow-2x">
          { Object.values(customLearningScenariosGrouped).map((group, i) => <div key={ i } className="pane pad-s no-underline">
            { group.map(rec => <div key={ rec.id } className="pane-item">
              <div className="space-between">
                <div>
                  <Link to={ ["", "build-your-own", "edit"].join("/") } state={ {hiddenid: rec.id} }>
                    <HeadingUnderline title={ rec.title } titleWeight="h3" />
                  </Link>
                  { state.imports.find(r => r.id === rec.id) && <span className="title-note">new</span> }
                </div>
                <div>
                  <Link to={ ["", "build-your-own", "edit"].join("/") } state={ {hiddenid: rec.id} } className="icon-button">✎ edit</Link>
                  <button className="icon-button" onClick={ () => { handleDeleteCustomLsClick(rec.title, () => remove(dispatch.ls, rec.id)) } }>× delete</button>
                </div>
              </div>
              { rec.time && <span>{ formatDateTime(new Date(rec.time), pageContext.language) }</span> }
            </div>) }
          </div>) }
        </div>
      </Row> }

    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {in: [$language, "pt", "en"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default BuildYourOwn
